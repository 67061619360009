import React from 'react'
import { FullWidthVerticallyCenteredCard } from '../cards'
import { CardHeader, BigParagraph } from '../../typography'
import * as colors from '../../colors.module.css'


export default function OurMission() {
  return (
    <FullWidthVerticallyCenteredCard className={colors.navyBackground}>
      <CardHeader className={colors.red}>Our Mission</CardHeader>
      <BigParagraph className={colors.white}>is to leverage the power of sports and African pride to develop leadership, mental wellbeing, physical fitness, and organizational skills in African youth</BigParagraph>
    </FullWidthVerticallyCenteredCard>
  )
}
