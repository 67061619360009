import React from 'react'
import { FullWidthCard } from '../cards'
import { CardHeader, BigParagraph } from '../../typography'
import * as colors from '../../colors.module.css'
import * as PartnerStyles from './partners.module.css'



export default function Partners() {
  return (
    <FullWidthCard className={colors.whiteBackground}>
      <CardHeader>Our Partners</CardHeader>
      <BigParagraph>We are proud to work
      with partners who promote
      sports and equality worldwide</BigParagraph>
      <div className={PartnerStyles.partnerImages}>
        <img className={PartnerStyles.partnerImage} src="/xfrance.png" alt="Expertise France" />
        <img className={PartnerStyles.partnerImage} src="/ingress-health-partners.png" alt="Ingress Health Partners" />
        <img className={PartnerStyles.partnerImage} src="/learning-impact.png" alt="Learning Impact" />
      </div>
    </FullWidthCard>
  )
}
