import React from 'react'
import scriptLoaded from '../../effects/onScriptLoaded'
import { FullWidthCard } from '../cards'
import * as styles from './facebook-feed.module.css'

export const scriptUrl =
  'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=1226978687453902&autoLogAppEvents=1'

const FacebookFeed = () => {
  const ref = React.useRef()
  const [hidden, setHidden] = React.useState(true)

  React.useEffect(() => {
    // The facebook script needs to have run before we add this element, otherwise the element won't get replaced by the
    // corresponding Facebook timeline in an iframe.
    ;(async function () {
      try {
        await scriptLoaded(scriptUrl, () => !!window.FB)
        ref.current.innerHTML = `
          <div
            class="fb-page"
            data-href="https://www.facebook.com/dreamsportsafrica/"
            data-tabs="timeline"
            data-width="${window.innerWidth - 50}"
            data-height="${window.innerHeight / 2}"
            data-small-header="true"
            data-adapt-container-width="false"
            data-hide-cover="false"
            data-show-facepile="false"
          >
          </div>
        `
        setHidden(false)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  return <FullWidthCard ref={ref} className={hidden ? styles.hide : ''} />
}

export default FacebookFeed
