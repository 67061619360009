// extracted by mini-css-extract-plugin
export const signupContainer = "signup-module--signupContainer--2Cw22";
export const show = "signup-module--show--1SACy";
export const slideIn = "signup-module--slideIn--3WCEe";
export const hide = "signup-module--hide--1ou9Q";
export const slideOut = "signup-module--slideOut--pHntg";
export const floatingHead = "signup-module--floatingHead--21WXV";
export const speechBubble = "signup-module--speechBubble--2Yoh5";
export const speechBubble__contents = "signup-module--speechBubble__contents--2XbV0";
export const speechBubble__text = "signup-module--speechBubble__text--2JnK8";
export const signupForm = "signup-module--signupForm--3zTu6";
export const email = "signup-module--email--3s7xW";
export const signupButton = "signup-module--signupButton--2pXka";
export const fadeIn = "signup-module--fadeIn--DWWV8";
export const fadeOut = "signup-module--fadeOut--37Ki4";