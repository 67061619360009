import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import { LeftRightCards, RedbackgroundCard, ImageCard } from '../cards'
import * as styles from './inspiring-quote.module.css'

const InspiringQuote = () => {
  return (
    <LeftRightCards style={{ minHeight: '600px' }}>
      <ImageCard>
        <StaticImage src="../../images/jump-clap.jpeg" alt="jump clap" imgStyle={{ objectPosition: '50% 55%' }} className={styles.imgContainer} /> 
      </ImageCard>

      <RedbackgroundCard>
        <blockquote>
          <p className={styles.inspiringQuote}>
            "Sport has the power to change the world. It has the power to
            inspire, it has the power to unite people in a way that little else
            does. It speaks to youth in a language they understand. Sport can
            create hope, where once there was only despair."
          </p>
          <cite
            style={{
              fontSize: '2em',
              textTransform: 'uppercase',
              fontStyle: 'normal'
            }}
          >
            Nelson Mandela
          </cite>
        </blockquote>
      </RedbackgroundCard>
    </LeftRightCards>
  )
}

export default InspiringQuote
