import React from "react";
import * as HeroStyle from "./hero.module.css"
import useExplicitHeightOnIPhone from "../../effects/useExplicitHeightOnIphone";


export default function Hero() {
    const ref = React.useRef()

    useExplicitHeightOnIPhone(ref)

    return (
        <section className={HeroStyle.callout} ref={ref}>
            <video autoPlay playsInline loop muted poster="../images/naijacore_frame_small.png">
                <source src="naijacore.mp4" type="video/mp4" />
                <source src="naijacore.webm" type="video/webm" />
            </video>
            <div className={HeroStyle.videoOverlay}></div>
            <div className={HeroStyle.calloutInner}>
                <h1 className={HeroStyle.calloutHeader}>
                    Help develop African leaders through sports
                </h1>
            </div>
        </section>
    );
}

