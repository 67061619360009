import React from 'react'
import * as cls from 'classnames'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import EmailAtIcon from './email-at-icon'
import InstagramIcon from './instagram-icon'
import * as socialMediaStyles from './social-media.module.css'

const SocialMediaBar = ({ showSocialMedia }) => {
  const dsaURL = 'https://dreamsportsafrica.org/'
  const PageTitle = 'Dream Sports Africa'
  const facebookURL = 'https://facebook.com/dreamsportsafrica/'
  const facebookShortSummary =
    'Dream Sports Africa is an international nonprofit that implements youth development through sports programs in Africa through fundraising and advocacy'
  const twitterURL = 'https://twitter.com/DreamSportsAfr'
  const twitterShortSummary =
    'DSA is a US based non profit that empowers African youth through sports.'
  const instagramURL = 'https://instagram.com/dreamsportsafrica/'
  const instagramShortSummary =
    'Dream Sports Africa is a US and Africa based nonprofit that raises funds and implements youth development through sports programs in Africa.'

    const linkedInURL = 'https://linkedin.com/company/dreamsportsafrica/'
  const linkedInSummary =
    'Dream Sports Africa is a nonprofit helping Africans reach their full potential through sports and exercise programs that develop character, life skills and physical fitness.'

  const iconProps = { size: 45, round: true }

  return (
    <div className={cls(socialMediaStyles.socialMediaBar, showSocialMedia && socialMediaStyles.show)}>
      <FacebookShareButton url={facebookURL} title={facebookShortSummary}>
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>

      <TwitterShareButton url={twitterURL} title={twitterShortSummary}>
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>

      <a href={instagramURL} className={socialMediaStyles.instagramLink}>
        <InstagramIcon />
      </a>

      <LinkedinShareButton url={linkedInURL} title={linkedInSummary}>
        <LinkedinIcon {...iconProps} />
      </LinkedinShareButton>

      <EmailShareButton
        openShareDialogOnClick
        url={dsaURL}
        subject={PageTitle}
        body={`${twitterShortSummary} Learn more at`}
      >
        <EmailAtIcon {...iconProps} />
      </EmailShareButton>
    </div>
  )
}

export default SocialMediaBar
