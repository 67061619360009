import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import { LeftRightCards, RedbackgroundCard, ImageCard } from '../cards'
import { CardHeader, BigParagraph } from '../../typography'
import ArrowLink from '../arrow-link'
import * as styles from './our-dream.module.css'

const OurDream = () => {
  return (
    <LeftRightCards style={{ minHeight: '600px' }}>
      <RedbackgroundCard>
        <CardHeader className={styles.ourDreamHeader}>
          Our Dream
        </CardHeader>
        <BigParagraph>
          is for young Africans to be leaders in their communities
        </BigParagraph>
        <ArrowLink
          text="Meet the dream team"
          to="/who-we-are"
          color="#282862"
          className={styles.link}
        />
      </RedbackgroundCard>
      <ImageCard>
        <StaticImage 
          src="../../images/great-smile-girl-basketball.png" 
          alt="girl smiling with basketball" 
          className={styles.imgContainer} 
          imgClassName={styles.ourDreamImg}
        />
      </ImageCard>
    </LeftRightCards>
  )
}

export default OurDream
