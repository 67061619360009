import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FullWidthCard, PictureCardWithInteriorBorder, PictureCards } from '../cards'
import * as styles from './highlights.module.css'
import * as colors from '../../colors.module.css'
import { CardHeader } from '../../typography'
import ArrowLink, { ArrowLinkRow } from '../arrow-link'

const borderColors = [
  "#ef4b40",
  "#282862",
  "#F8BE20",
]

const Highlights = ({ posts }) => {
  return (
    <FullWidthCard>
      <CardHeader className={colors.red}>Highlights</CardHeader>
      <PictureCards>
        {posts.slice(0, borderColors.length).map(({ node }, index) => {
          const article = node
          const highlightsHeroImage = getImage(article.heroImage);
          const color = borderColors[index]
          const articleDescription = article.description && article.description.description || ''
          const articleHref = `/highlights/${article.slug}`
          return (
            <PictureCardWithInteriorBorder
              img={
                <GatsbyImage image={highlightsHeroImage} />
              }
              color={color}
              key={index}
            >
              <div className={styles.articleContent}>
                <h3 className={styles.previewTitle} style={{ color }}>
                  <Link to={articleHref}>{article.title}</Link>
                </h3>
                <p className={styles.publishDate}>{article.publishDate}</p>
                <p className={styles.articleDescription}>{articleDescription}</p>
                <div className={styles.readMore}>
                  <ArrowLink
                    text="Read More"
                    to={articleHref}
                    color={color}
                  />
                </div>
              </div>
            </PictureCardWithInteriorBorder>
          )
        })}
      </PictureCards>
      <ArrowLinkRow
        text="See more highlights"
        to="/highlights"
        color="#282862"
      />
    </FullWidthCard>
  )
}

export default Highlights
