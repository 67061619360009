import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../layout/layout'
import OurDream from '../components/our-dream'
import OurMission from '../components/our-mission'
import OurImpact from '../components/our-impact'
import OurPartners from '../components/our-partners'
import InspiringQuote from '../components/inspiring-quote'
import Hero from '../components/hero'
import FacebookFeed, { scriptUrl as facebookScriptUrl } from '../components/facebook-feed'
import Highlights from '../components/highlights'
import Signup from '../components/signup'
import SocialMediaBar from '../components/social-media-bar'


export default function RootIndex(props) {
  const posts = props.data.allContentfulHighlight.edges

  const [animateSection, setAnimateSection] = React.useState(null)

  React.useEffect(() => {
    function callback() {
      if (window.scrollY > 5500) {
        setAnimateSection(null)
      } else if (window.scrollY > 2500) {
        setAnimateSection('socialMedia')
      } else if (window.scrollY > 500) {
        setAnimateSection('signup')
      } else {
        setAnimateSection(null)
      }
    }

    window.addEventListener("scroll", callback)
    window.addEventListener("resize", callback)

    return () => {
      window.removeEventListener("scroll", callback)
      window.removeEventListener("resize", callback)
    }
  }, [])

  return (
    <Layout location={props.location}>
      <div>
        <Helmet title='Dream Sports Africa'>
          <meta property='og:type' content='video.other' />
          <meta property='og:video:url' content='naijacore.mp4' />
          <meta property='og:video:secure_url' content='naijacore.mp4' />
          <meta property='og:video:type' content='video/mp4' />
          <meta property='og:video:height' content='720' />
          <meta property='og:title' content='Dream Sports Africa' />
          <meta
            property='og:description'
            content='Dream Sports Africa ia a non-profit that develops African leaders through sports.'
          />
          <meta name='twitter:title' content='Dream Sports Africa' />
          <meta
            name='twitter:description'
            content='Dream Sports Africa ia a non-profit that develops African leaders through sports.'
          />
          <meta property='og:image' content='naijacore_frame_small.png' />
          <meta property="og:site_name" content="Dream Sports Africa" />
          <meta property="og:type" content="website" />
          <meta property="og:updated_time" content={Math.floor(Date.now() / 1000)} />
          <script async defer crossorigin='anonymous' src={facebookScriptUrl} nonce='2Jn3pth5' />
          <script dangerouslySetInnerHTML={{
            __html: `// jankyscroll.js v2.0
            // what's wrong with you
            var jankyscroll = (function() {
                'use strict';
                function coinflip () {
                    return ((Math.random() * 2)|0) * 2 - 1;
                }

                function intErval() {
                    return (Math.random() * (12 - 8 + 1) + 8) / 10;
                }

                return function(trigger_distance, jank_range, jank_method) {
                    trigger_distance = trigger_distance || 400;
                    jank_range = jank_range || 20;
                    if (typeof jank_method !== 'function') {
                        jank_method = (function() {
                            switch (jank_method) {
                              case "offset":
                                  return function(val) {
                                      document.body.style.transform = "translateY(" + val + "px)";
                                  };
                              case "hardcore":
                                  return function(val) {
                                      var all = document.getElementsByTagName("*");
                                      for (var i = 0; i < all.length; i++) {
                                          all[i].style.transform = "translateY(" + (val * (Math.random() / 2 + 0.75)) + "px)";
                                      }
                                  }
                              case "scroll":
                              default:
                                  return function(val) {
                                      window.scrollBy(0, val);
                                  };
                            }
                        })();
                    }
                    var lastOffset;

                    var scrollJank = function() {
                        var scrollOffset = Math.floor(window.scrollY / trigger_distance);
                        if (lastOffset !== scrollOffset) {
                            jank_method(jank_range * intErval() * coinflip());
                            lastOffset = scrollOffset;
                        }
                    };

                    var timer;

                    function resizeJank() {
                        if (timer)
                            clearTimeout(timer);
                        timer = setTimeout(function() {
                            jank_method(jank_range * intErval() * coinflip());
                        }, 400);
                    }

                    window.addEventListener('scroll', scrollJank, false);
                    window.addEventListener('resize', resizeJank, false);
                };
            })();
            `
          }}>

          </script>
        </Helmet>
        <Hero />
        <OurDream />
        <OurMission />
        <OurImpact />
        <Highlights posts={posts} />
        <InspiringQuote />
        <OurPartners />
        <FacebookFeed />
        <SocialMediaBar showSocialMedia={animateSection === 'socialMedia'} />
        <Signup showSignup={animateSection === 'signup'} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulHighlight(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            gatsbyImageData(
              resizingBehavior: NO_CHANGE
              placeholder: TRACED_SVG
              aspectRatio: 1
            )
          }
          description {
            childMarkdownRemark {
              html
            }
            description
          }
        }
      }
    }
  }
`
