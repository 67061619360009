import React from 'react'
import * as cls from 'classnames'
import * as styles from './signup.module.css'
import * as colors from '../../colors.module.css'

const SignupForm = () => (
  <form
    method="POST"
    action="/.netlify/functions/signup"
    className={styles.signupForm}
  >
    <input
      type="email"
      name="email"
      required
      placeholder="Email"
      className={styles.email}
    />
    <button type="submit" className={styles.signupButton}>
      Sign Up
    </button>
  </form>
)

function Signup({ showSignup }) {
  const [wasShown, setWasShown] = React.useState(false)

  React.useEffect(() => {
    if (showSignup) setWasShown(true)
  }, [showSignup])

  const showHideClass = showSignup ? styles.show : (wasShown && styles.hide)

  return (
    <div className={cls(styles.signupContainer, showHideClass)}>
      <div className={styles.floatingHead}>
        <img src="/mouth-centered-melody-face.png" alt="Melody" />
      </div>
      <div className={cls(styles.speechBubble, colors.navyBackground)}>
        <div className={styles.speechBubble__contents}>
          <div className={styles.speechBubble__text}>
            How far?! I'm Melody, one of DSA's captains.
            Drop your email to stay in the loop about awesome things happening in our community! 🤸🏿‍♀️🏀⚽️
            <SignupForm />
          </div>
        </div>
      </div>

    </div>
  )
}

export default Signup
